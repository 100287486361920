export const songs = [
  {
    artist: "Mastodon",
    name: "The Motherload",
    youtubeId: "wz56aruweJY",
  },
  {
    artist: "Metallica",
    name: "St. Anger",
    youtubeId: "ar9BaNN5J4E",
  },
  {
    artist: "The Mars Volta",
    name: "Drunkship of Lanterns",
    youtubeId: "fE6YN9VcPPA",
  },
  {
    artist: "Primus",
    name: "Jerry Was A Race Car Driver",
    youtubeId: "_oanJVP5Tg8",
  },
  {
    artist: "Devin Townsend Project",
    name: "Kingdom",
    youtubeId: "9pq3w12McyM",
  },
  {
    artist: "Coheed And Cambria",
    name: "Welcome Home",
    youtubeId: "JJJ27NxYamY",
  },
  {
    artist: "Halestorm",
    name: "I Miss the Misery",
    youtubeId: "V5OC2IZW3Pg",
  },
  {
    artist: "JINJER",
    name: "Pisces",
    youtubeId: "0r8c69QcoNM",
  },
  {
    artist: "Puscifer",
    name: "The Remedy",
    youtubeId: "gQaEXRI1Vxk",
  },
  {
    artist: "State Champs",
    name: "Secrets",
    youtubeId: "Keh4QXsHYac",
  },
  {
    artist: "Cancer Bats",
    name: "Hail Destroyer",
    youtubeId: "08FrB5dgIqQ",
  },
  {
    artist: "Architects",
    name: "Animals",
    youtubeId: "rB9QX4Tsjos",
  },
  {
    artist: "Pendulum",
    name: "Tarantula",
    youtubeId: "zVch_PK7SOY",
  },
  {
    artist: "Neck Deep",
    name: "In Bloom",
    youtubeId: "5Y48hMzrUjM",
  },
  {
    artist: "Hot Milk",
    name: "Candy Coated Lies",
    youtubeId: "s-TEXTNUsvs",
  },
  {
    artist: "Stand Atlantic",
    name: "Coffee at Midnight",
    youtubeId: "B0uIqmAshOM",
  },
  {
    artist: "Alexisonfire",
    name: "This Could Be Anywhere In The World",
    youtubeId: "aiLLQH9Djvc",
  },
  {
    artist: "Clutch",
    name: "The Regulator",
    youtubeId: "d4WlqxPzQ28",
  },
  {
    artist: "Ice Nine Kills",
    name: "A Grave Mistake",
    youtubeId: "srlSAxxvfXE",
  },
  {
    artist: "Parkway Drive",
    name: "Wild Eyes",
    youtubeId: "UU8xKUoH_lU",
  },
  {
    artist: "I Prevail",
    name: "Alone",
    youtubeId: "lGWGDRwgZCA",
  },
  {
    artist: "Behemoth",
    name: "O Father O Satan O Sun",
    youtubeId: "4hVj32UmUyQ",
  },
  {
    artist: "Asking Alexandria",
    name: "Not The American Average",
    youtubeId: "nwLz_PJEiVE",
  },
  {
    artist: "Simple Plan",
    name: "Last One Standing",
    youtubeId: "uurkmfkKodg",
  },
  {
    artist: "MUNICIPAL WASTE",
    name: "The Art Of The Partying",
    youtubeId: "H3eX1hPSoQw",
  },
  {
    artist: "Three Days Grace",
    name: "I Hate Everything About You",
    youtubeId: "BpwCJzPlz8k",
  },
  {
    artist: "City And Colour",
    name: "The Girl",
    youtubeId: "LxMtBQUTu-s",
  },
  {
    artist: "Bring Me The Horizon",
    name: "Can You Feel My Heart",
    youtubeId: "nNbZJ-IgAEg",
  },
  {
    artist: "Megadeth",
    name: "A Tout Le Monde",
    youtubeId: "dnu2kPF6ty8",
  },
  {
    artist: "Rings of Saturn",
    name: "Faces Imploding",
    youtubeId: "177yN1N5G5g",
  },
  {
    artist: "Three Days Grace",
    name: "I Hate Everything About You",
    youtubeId: "gqbQuypKCCU",
  },
  {
    artist: "TOOL",
    name: "Sober",
    youtubeId: "u68wKZFJVws",
  },
  {
    artist: "Porcupine Tree",
    name: "Open Car",
    youtubeId: "uvpVyziILlM",
  },
  {
    artist: "Deftones",
    name: "Knife Party",
    youtubeId: "x40qeZlTJQo",
  },
  {
    artist: "Arctic Monkeys",
    name: "Do I Wanna Know?",
    youtubeId: "PUL2mVdt3xo",
  },
  {
    artist: "Radiohead",
    name: "Paranoid Android",
    youtubeId: "0lv7pCotRIo",
  },
  {
    artist: "Pearl Jam",
    name: "Alive",
    youtubeId: "wGiTPgvKktM",
  },
  {
    artist: "The White Stripes",
    name: "Seven Nation Army",
    youtubeId: "KcZ73FRFLzY",
  },
  {
    artist: "Rammstein",
    name: "Du Hast",
    youtubeId: "hN4OXPZtHtI",
  },
  {
    artist: "Nine Inch Nails",
    name: "Closer",
    youtubeId: "1tOaQsNa5Js",
  },
  {
    artist: "Smashing Pumpkins",
    name: "Tonight, Tonight",
    youtubeId: "JX7g9vPo4Uw",
  },
  {
    artist: "Muse",
    name: "Time Is Running Out",
    youtubeId: "ZrujXrB1_aE",
  },
  {
    artist: "Bon Jovi",
    name: "Livin' On A Prayer",
    youtubeId: "ohFtQIPqGSo",
  },
  {
    artist: "Scorpins",
    name: "Rock You Like A Hurricane",
    youtubeId: "CGfKi6kpdTQ",
  },
  {
    artist: "VOLBEAT",
    name: "Lola Montez",
    youtubeId: "DdPpRdImfuA",
  },
  {
    artist: "Five Finger Death Punch",
    name: "Wash It All Away",
    youtubeId: "ioMS4uVB0DA",
  },
  {
    artist: "Royal Blood",
    name: "Little Monster",
    youtubeId: "locpjD8xFeE",
  },
  {
    artist: "Aerosmith",
    name: "Walk This Way",
    youtubeId: "5UFFa1HbFfA",
  },
  {
    artist: "Kiss",
    name: "Detroit Rock City",
    youtubeId: "lfnBJ2CXeRE",
  },
  {
    artist: "Green Day",
    name: "Boulevard of Broken Dreams",
    youtubeId: "CohyUUzTd1M",
  },
  {
    artist: "AC/DC",
    name: "T.N.T.",
    youtubeId: "Mlnyi3QtPts",
  },
  {
    artist: "Megadeth",
    name: "In my darkest hour",
    youtubeId: "zm72p_AXOnU",
  },
  {
    artist: "Nickelback",
    name: "Gotta Be Somebody",
    youtubeId: "VP2nig9kwpU",
  },
  {
    artist: "Iron Maiden",
    name: "Run To The Hills",
    youtubeId: "VKqDSn1MzG0",
  },
  {
    artist: "The Offspring",
    name: "Keep Em Separated",
    youtubeId: "G2gTFBhQ7Ko",
  },
  {
    artist: "Rainbow",
    name: "Since You Been Gone",
    youtubeId: "N73l_rge834",
  },
  {
    artist: "Linkin Park",
    name: "New Divide",
    youtubeId: "Sk2x_k1GWt8",
  },
  {
    artist: "Tenacious D",
    name: "Video Games",
    youtubeId: "GNJtPFXUnm4",
  },
  {
    artist: "Van Halen",
    name: "Eruption",
    youtubeId: "M4Czx8EWXb0",
  },
  {
    artist: "Rival Sons",
    name: "Electic Man",
    youtubeId: "XtE-8_pUtC8",
  },
  {
    artist: "The Pretty Reckless",
    name: "Take Me Down",
    youtubeId: "xcLZNtNOLFk",
  },
  {
    artist: "Uriah Heep",
    name: "Gypsy",
    youtubeId: "3V3rmsMrAeg",
  },
  {
    artist: "Jimi Hendrix",
    name: "Lover Man",
    youtubeId: "iKN8mAiBtbA",
  },
  {
    artist: "Arctic Monkeys",
    name: "Arabella",
    youtubeId: "LjyCNFEAMmM",
  },
  {
    artist: "Motörhead",
    name: "Tear Ya Down",
    youtubeId: "z6673YF1WwM",
  },
  {
    artist: "TOOL",
    name: "Rosetta Stoned",
    youtubeId: "qnlhVVwBfew",
  },
  {
    artist: "Jack Black",
    name: "Peaches",
    youtubeId: "OlQJ2zy5DE8",
  },
  {
    artist: "Motley Crue",
    name: "Shout at the Devil",
    youtubeId: "sgm6fu-1wQc",
  },
  {
    artist: "Nickelback",
    name: "Burn It to the Ground",
    youtubeId: "YZRzi-xr_1M",
  },
  {
    artist: "Black Sabbath",
    name: "Sweet Leaf",
    youtubeId: "W-zmtmgswHw",
  },
  {
    artist: "Iron Maiden",
    name: "Phantom of the Opera",
    youtubeId: "wXlhsrIzmpo",
  },
  {
    artist: "Linkin Park",
    name: "Somewhere I Belong",
    youtubeId: "zsCD5XCu6CM",
  },
  {
    artist: "Pantera",
    name: "Mouth For War",
    youtubeId: "a3JSbOt7CLo",
  },
  {
    artist: "Kiss",
    name: "Heaven's On Fire",
    youtubeId: "EZjevnnkA20",
  },
  {
    artist: "The Offspring",
    name: "Self Esteem",
    youtubeId: "Abrn8aVQ76Q",
  },
  {
    artist: "Tenacious D",
    name: "Rise Of The Fenix",
    youtubeId: "HczojOJ1XxQ",
  },
  {
    artist: "Alter Bridge",
    name: "Open Your Eyes",
    youtubeId: "a4Rp-PEDGdQ",
  },
  {
    artist: "Jimi Hendrix",
    name: "Freedom",
    youtubeId: "TxI1WWbLOSo",
  },
  {
    artist: "Led Zeppelin",
    name: "Fool in the Rain",
    youtubeId: "I57nIP0vc44",
  },
  {
    artist: "Metallica",
    name: "Until It Sleeps",
    youtubeId: "eRV9uPr4Dz4",
  },
  {
    artist: "A Perfect Circle",
    name: "The Hollow",
    youtubeId: "CjMU60FZETY",
  },
  {
    artist: "Ashes Divide",
    name: "The Stone",
    youtubeId: "lAO_dFyY6yg",
  },
  {
    artist: "Blink 182",
    name: "First Date",
    youtubeId: "_FCoUBEJAVg",
  },
  {
    artist: "Soundgarden",
    name: "Flower",
    youtubeId: "va9BIPt6_-o",
  },
  {
    artist: "Slipknot",
    name: "The Devil in I",
    youtubeId: "dxnQvmRv6uw",
  },
  {
    artist: "Nirvana",
    name: "Drain You",
    youtubeId: "YyPavIQnaOc",
  },
  {
    artist: "Bowling For Soup",
    name: "Punk Rock 101",
    youtubeId: "Ajq4Ek-jChA",
  },
  {
    artist: "Rush",
    name: "Finding My Way",
    youtubeId: "-YBp4hv91CI",
  },
  {
    artist: "Creedence Clearwater Revival",
    name: "Bad Moon Rising",
    youtubeId: "zUQiUFZ5RDw",
  },
  {
    artist: "UFO",
    name: "Rock Bottom",
    youtubeId: "xTE4NNqmnbw",
  },
  {
    artist: "Queens of The Stone Age",
    name: "Little Sister",
    youtubeId: "hGRqnNEOpe0",
  },
  {
    artist: "InMe",
    name: "Underdose",
    youtubeId: "Sup3LsIy6Ow",
  },
  {
    artist: "Motley Crue",
    name: "Dr. Feelgood",
    youtubeId: "OlbE0urutpM",
  },
  {
    artist: "MUNICIPAL WASTE",
    name: "Sadistic Magician",
    youtubeId: "KqyG4y_bbPQ",
  },
  {
    artist: "A Perfect Circle",
    name: "The Outsider",
    youtubeId: "nzyNWyZhUS0",
  },
  {
    artist: "Linkin Park",
    name: "One More Light",
    youtubeId: "vst1Z76gIY4",
  },
  {
    artist: "Tenacious D",
    name: "Kickapoo",
    youtubeId: "3kZopZhAub8",
  },
  {
    artist: "Soundgarden",
    name: "Rusty Cage",
    youtubeId: "HKKyXawoM4c",
  },
  {
    artist: "Led Zeppelin",
    name: "Misty Mountain Hop",
    youtubeId: "2cOYzDtpEmI",
  },
  {
    artist: "Royal Blood",
    name: "Typhoons",
    youtubeId: "9DKE1dAxN74",
  },
  {
    artist: "Motörhead",
    name: "Stay Clean",
    youtubeId: "_m9tx1s6rFQ",
  },
  {
    artist: "TOOL",
    name: "Bottom",
    youtubeId: "7TEui6b2TxY",
  },
  {
    artist: "Radiohead",
    name: "Just",
    youtubeId: "Qnk4jiWamm4",
  },
  {
    artist: "Black Sabbath",
    name: "Tomorrow's Dream",
    youtubeId: "KaN5Acc8owk",
  },
  {
    artist: "Slash",
    name: "Doctor Alibi",
    youtubeId: "9XhSyMLHQgc",
  },
  {
    artist: "Iron Maiden",
    name: "Hallowed Be Thy Name",
    youtubeId: "NICOAY3anp8",
  },
  {
    artist: "Rush",
    name: "A Passage To Bangkok",
    youtubeId: "zaC041pQl4I",
  },
  {
    artist: "Kiss",
    name: "I Love It Loud",
    youtubeId: "fxWpEZfM0eM",
  },
  {
    artist: "Disturbed",
    name: "The Animal",
    youtubeId: "qyxrpmD5cq0",
  },
  {
    artist: "Korn",
    name: "Right Now",
    youtubeId: "RV7C40HIt9g",
  },
  {
    artist: "Them Crooked Vultures",
    name: "Elephants",
    youtubeId: "t7_PQiEIL8c",
  },
  {
    artist: "Alter Bridge",
    name: "Godspeed",
    youtubeId: "UND4qN0baL0",
  },
  {
    artist: "Bowling For Soup",
    name: "Almost",
    youtubeId: "UNbOr0ylYZk",
  },
  {
    artist: "Deftones",
    name: "Change (In The House Of Flies)",
    youtubeId: "A3ImpLn46MU",
  },
  {
    artist: "Sum 41",
    name: "Still Waiting",
    youtubeId: "Kjwlzs1OJcY",
  },
  {
    artist: "Queens of the Stone Age",
    name: "Mexicola",
    youtubeId: "1F1fY1p9eh4",
  },
  {
    artist: "Rush",
    name: "Best I Can",
    youtubeId: "3rluWcMcpDQ",
  },
  {
    artist: "Creedence Clearwater Revival",
    name: "Green River",
    youtubeId: "L5V9nK7-OkM",
  },
  {
    artist: "Nirvana",
    name: "In Bloom",
    youtubeId: "bMvPMAGjdJU",
  },
  {
    artist: "Black Sabbath",
    name: "Into the Void",
    youtubeId: "-R5XnrZn47Q",
  },
  {
    artist: "Nickelback",
    name: "Hero",
    youtubeId: "djMxEuT_vgY",
  },
  {
    artist: "Them Crooked Vultures",
    name: "New Fang",
    youtubeId: "S7_vH3H8LPI",
  },
];
