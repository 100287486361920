import React from "react";
import { IoMusicalNotesSharp, IoInformationCircleSharp } from "react-icons/io5";
import { Button } from "..";

import * as Styled from "./index.styled";

interface Props {
  onClose: () => void;
}

export function InfoPopUp({ onClose }: Props) {
  return (
    <Styled.Container>
      <Styled.PopUp>
        <h1>Welcome! 🤘</h1>
        <h3>
          Song list has been updated! Thanks for the song suggestions, keep them
          coming
        </h3>
        <Styled.Spacer />
        <Styled.Section>
          <IoMusicalNotesSharp size={60} />
          <p>
            Based on the popular game heardle but with Rock / Metal songs{" "}
            <a href="https://heardle.app">Heardle</a>.
          </p>
        </Styled.Section>
        <Styled.Section>
          <IoInformationCircleSharp size={130} />
          <p>
            Listen to the intro, then find the correct artist and title in the
            list. Skipped or incorrect attempts unlock more of the intro. Answer
            in as few tries as possible and share your score
          </p>
        </Styled.Section>
        <Button variant="green" style={{ marginTop: 20 }} onClick={onClose}>
          Close
        </Button>
        <Styled.Contact>
          Contact Us - <a>rockheardle@gmail.com</a>{" "}
          <p>
            Privacy Policy -{" "}
            <a href="https://www.privacypolicies.com/live/15621ec0-0064-4b6e-b0f4-c9f6c444add7">
              View
            </a>
          </p>
        </Styled.Contact>
      </Styled.PopUp>
    </Styled.Container>
  );
}
