import React, { useState } from "react";
import { IoInformationCircleOutline, IoMenuOutline } from "react-icons/io5";

import * as Styled from "./index.styled";

interface Props {
  openInfoPopUp: () => void;
}

export function Header({ openInfoPopUp }: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("");

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = (menuItem: string) => {
    setActiveMenuItem(menuItem);
    setIsMenuOpen(false);
  };

  return (
    <Styled.Container>
      <Styled.Content>
        <div style={{ position: "relative" }}>
          <IoMenuOutline onClick={handleMenuToggle} size={30} />
          {isMenuOpen && (
            <Styled.Menu>
              <Styled.MenuItem>
                <a
                  href="https://rockheardle.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rock Heardle
                </a>
              </Styled.MenuItem>
              <Styled.MenuItem>
                <a
                  href="https://www.metalheardle.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Metal Heardle
                </a>
              </Styled.MenuItem>
              <Styled.MenuItem>
                <a
                  href="https://www.rockheardle.com/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Song Requests
                </a>
              </Styled.MenuItem>
              <Styled.MenuItem>
                <a
                  href="https://www.privacypolicies.com/live/15621ec0-0064-4b6e-b0f4-c9f6c444add7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </Styled.MenuItem>
            </Styled.Menu>
          )}
        </div>
        <Styled.Logo>Rock&nbsp;Heardle 🤘</Styled.Logo>
        <IoInformationCircleOutline
          onClick={openInfoPopUp}
          size={30}
          style={{ marginLeft: "auto" }}
        />
        {activeMenuItem === "Rock Heardle"}
        {activeMenuItem === "Metal Heardle"}
        {activeMenuItem === "Contact"}
        {activeMenuItem === "Privacy Policy"}
      </Styled.Content>
    </Styled.Container>
  );
}
