import React, { useRef } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 300px;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* add this line */

  & label {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
  }

  & input,
  & textarea {
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 3px;
    font-size: 16px;
  }

  & textarea {
    resize: none;
    height: 200px;
  }

  & input[type="submit"] {
    background-color: #00bfff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #0099cc;
    }
  }
`;

export default function ContactPage() {
  const formRef = useRef<HTMLFormElement | null>(null);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rbqz79p",
        "template_awlys89",
        formRef.current!,
        "IaiPJYRdPq8NHeX8U"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          alert("Your message has been sent!");
          formRef.current!.reset(); // Reset the form
        },
        (error) => {
          console.log(error.text);
          alert("There was an error sending your message.");
        }
      );
  };

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Contact Us</h2>
      <p style={{ textAlign: "center" }}>
        Do you have any song suggestions we can add to the game? Fill out the
        form and let us know
      </p>
      <ContactForm ref={formRef} onSubmit={sendEmail}>
        <label>Name</label>
        <input type="text" name="user_name" />
        <label>Email</label>
        <input type="email" name="user_email" />
        <label>Message</label>
        <textarea name="message" />
        <input type="submit" value="Send" />
      </ContactForm>
    </div>
  );
}
